import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';


@NgModule({
    imports: [AuthModule.forRoot({
        config: {
			triggerAuthorizationResultEvent: true,
            authority: environment.authorityUrl,
            redirectUrl: (document.querySelector('base') || {}).href + "auth/callback",
            postLogoutRedirectUri: (document.querySelector('base') || {}).href,
            clientId: environment.clientId,
            scope: 'openid offline_access esders', // 'openid profile ' + your scopes
            responseType: 'code',
            silentRenew: true,
			useRefreshToken: false,
			silentRenewUrl: (document.querySelector('base') || {}).href + 'silent-renew.html',
			ignoreNonceAfterRefresh: true,
            renewTimeBeforeTokenExpiresInSeconds: 10,
			maxIdTokenIatOffsetAllowedInSeconds: 300,
			logLevel: LogLevel.None,
        }
      })],
    exports: [AuthModule],
})
export class AuthConfigModule {}
