
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@esders/authentificate';
import { DummyComponent } from './components/dummy.component';
import { RedirectGuardService } from '@esders/utilities';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login-status', pathMatch: 'full' },
  {
	  path: "auth",
	  loadChildren: () => import("@esders/authentificate").then(m => m.AuthentificateModule)
  },
  {
	  path: "home",
	  canLoad: [AuthGuard],
	  canActivate: [AuthGuard],
	  loadComponent: () => import("./homepage/homepage.component").then(m => m.HomepageComponent)
  },
  {
	path: "measurement",
	canLoad: [AuthGuard],
	canActivate: [AuthGuard],
	loadChildren: () => import("./measurements/measurements.module").then(m => m.MeasurementsModule)
  },
  {
	path: "devicetest",
	canLoad: [AuthGuard],
	canActivate: [AuthGuard],
	loadChildren: () => import("./measurements/measurements.module").then(m => m.MeasurementsModule)
  },
  {
	path: "live-measurement",
	canLoad: [AuthGuard],
	canActivate: [AuthGuard],
	loadChildren: () => import("./livemeas/livemeas.module").then(m => m.LivemeasModule)
  },
  {
	path: "settings",
	canLoad: [AuthGuard],
	canActivate: [AuthGuard],
	loadChildren: () => import("./settings/settings.module").then(m => m.SettingsModule)
  },
  { 
	path: 'credit', 
	canLoad: [AuthGuard],
	canActivate: [AuthGuard],
	loadChildren: () => import("./credit/credit.module").then(m => m.CreditModule)
  },
  {
	path: "customerportal",
	loadChildren: () => import("./cutomerportal/cutomerportal.module").then(m => m.CutomerportalModule)
  },
  {
	path: "service",
	loadChildren: () => import("./service/service.module").then(m => m.ServiceModule)
  },
  {
	path: "salesoffers",
	loadChildren: () => import("./sales-offer/sales-offer.module").then(m => m.SalesOfferModule)
  },
  {
	path: "order",
	loadChildren: () => import("./order/order.module").then(m => m.OrderModule)
  },
  {
	path: "mde",
	canLoad: [AuthGuard],
	canActivate: [AuthGuard],
	loadChildren: () => import("./mde/mde.module").then(m => m.MdeModule)
  },
  {
	  path: "auftrags-formular",
	  canActivate: [RedirectGuardService],
	  data: {
		  redirectTo: "/order/token"
	  },
	  component: DummyComponent
  },
  { path: '**', redirectTo: '/auth/login-status' },
];

@NgModule({
   imports: [
       RouterModule.forRoot(routes)
   ],
   exports: [
      RouterModule
   ],
   declarations: [

   ]
})
export class AppRoutingModule { }
