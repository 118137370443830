import { PlatformModule } from '@angular/cdk/platform';
import { DatePipe } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AuthentificateModule, AuthService } from '@esders/authentificate';
import { ShopApiModule, ShopConfiguration } from '@esders/shop-api';
import { ThemeModule } from '@esders/theme';
import { UtilitiesModule } from '@esders/utilities';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthConfigModule } from './auth/auth-config.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { MatPaginatorI18nService } from './_services/mat-paginator-i18n.service';
import { ConnectApiModule, ConnectConfiguration } from '@esders/connect-api';
import { BUGREPORT_CONFIG, GuiModule } from '@esders/gui';
import { DummyComponent } from './components/dummy.component';
import { AnalyticsModule, TrackLinkDirective } from '@esders/analytics';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/cs';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/pt-br';
import 'moment/locale/zh-cn';

// import { FeedbackModule } from './feedback/feedback.module';
// import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

@NgModule({ declarations: [
        AppComponent,
        DummyComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        PlatformModule,
        MatExpansionModule,
        MatIconModule,
        MatTableModule,
        MatSelectModule,
        MatCardModule,
        GuiModule,
        ThemeModule,
        SharedModule,
        CoreModule,
        UtilitiesModule,
        TrackLinkDirective,
        AuthConfigModule,
        // FeedbackModule,
        // TourMatMenuModule,
        ShopApiModule,
        ConnectApiModule,
        AuthentificateModule.forRoot({
            rootUris: [
                environment.connectGwUri,
                environment.shoppingApiUri
            ],
            logoPath: "assets/images/Esders-Connect-512.png",
            afterLoginRoute: "/home",
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
        AnalyticsModule.forRoot({
            excludedRoutes: [/\/auth\/.*/g]
        }),
        LeafletModule], providers: [
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorI18nService,
        },
        {
            provide: BUGREPORT_CONFIG,
            useValue: {
                email: "bugreport@esders.de",
                application: environment.appId,
                version: environment.version
            }
        },
        {
            provide: ShopConfiguration,
            useFactory: (authService: AuthService) => new ShopConfiguration({
                basePath: environment.shoppingApiUri,
                credentials: { Bearer: authService.getTokenValue.bind(authService) }
            }),
            deps: [AuthService],
            multi: false
        },
        {
            provide: ConnectConfiguration,
            useFactory: (authService: AuthService) => new ConnectConfiguration({
                basePath: environment.connectGwUri,
                credentials: { Bearer: authService.getTokenValue.bind(authService) },
                // defaultHeaders: {
                // 	"X-Esders-App": environment.appId,
                // 	"X-Esders-Version": environment.version
                // }
            }),
            deps: [AuthService],
            multi: false
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
        // { provide: ESDERS_ANALYTICS_TOKEN, useValue: { settings: { enableContactLinkTracking: true }}},
        DatePipe,
        CookieService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "assets/i18n/");
}