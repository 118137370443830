import { Platform } from '@angular/cdk/platform';
import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {
	MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter
} from "@angular/material-moment-adapter";
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE
} from "@angular/material/core";
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService, Roles, RoleService } from '@esders/authentificate';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, Subscription, catchError, filter, shareReplay, switchMap, take } from 'rxjs';
import { environment } from "../environments/environment";
import { CompareService } from './core/services/compare.service';
import { TranslationHelperService } from '@esders/utilities';
import { DetailsearchService } from './core/services/detailsearch.service';
import { CustomerPortalSearchService } from './core/services/customer-portal-search.service';
import { BugReportService, DialogService } from '@esders/gui';
import { AnalyticsService } from '@esders/analytics';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DisclaimerService } from '@esders/theme';
import { UsersService } from '@esders/connect-api';

declare global {
	interface Window {
	  appInfo: string | undefined;
	}
  }

declare var _paq: any[];

@Component({
    selector: "app-root",
    encapsulation: ViewEncapsulation.None,
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    // The style is in global style.scss
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: "de-DE" },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class AppComponent implements OnInit, OnDestroy{
	Roles = Roles;

    env = environment;
    public mobileMenue: boolean = false;

    public backgroundShadowSpeed: number = 300;
    public currentLang: string;

 	public onLangChange: Subscription = undefined;
	private _isLoggedIn : boolean = false;

    constructor(
        public authService: AuthService,
		public translateService : TranslationHelperService,
        public _adapter: DateAdapter<any>,
        public router: Router,
        public comparar: CompareService,
        public el: ElementRef,
        public roleService: RoleService,
		private _detailSearchService : DetailsearchService,
		private _customerPortalSearchService : CustomerPortalSearchService,
		public platform : Platform,
		private _analyticsService: AnalyticsService,
		private _cookieService : CookieService,

		private _dialogService : DialogService,
		private _bugreportService: BugReportService,
		private _disclaimerService : DisclaimerService,
		private _userService: UsersService
    ) {
        this.translateService.addLangs(["en", "de", "nl", "ru", "zh", "es", "pl", "pt", "cs", "it", "fr"]);
		window.appInfo = this._bugreportService.generateReport();

		this._disclaimerService.onDisclaimerChoosen.pipe(takeUntilDestroyed()).subscribe(res => {
			if(!res)
				this.authService.doLogout();
			else
				this.authService.getUserInfo().pipe(filter(user => user), take(1), switchMap(_ => {
					return this._userService.setDiclaimerOk().pipe(
						catchError(_ => {
							this.authService.doLogout();
							return EMPTY;
						}),
						shareReplay(1),
						take(1)
					)
				})).subscribe(() => sessionStorage.setItem("disclaimerOk", "true"));
		});

		this.authService.isLoggedIn$.pipe(filter(res => res), take(1)).subscribe(res => {
			if(res) {
				this.authService.getUserInfo().pipe(filter(user => user), take(1)).subscribe(user => {
					this._analyticsService.setUser(user.mail);
					this._analyticsService.startTracking();
					this._isLoggedIn = res;

					if(!user.customerUserConfiguration.disclaimerOk && (!sessionStorage.getItem("disclaimerOk")))
						this._disclaimerService.chooseDisclaimer(false, user.contact);
				})
			}
		});

		router.events.pipe(
			filter(ev => ev instanceof NavigationStart),
			takeUntilDestroyed()
		).subscribe((_ : NavigationStart) => {
			this.mobileMenue = false;
			this._detailSearchService.saveFilter();
			this._customerPortalSearchService.saveFilter();
		})

		router.events.pipe(
			filter(ev => ev instanceof NavigationEnd),
			takeUntilDestroyed()
		).subscribe((ev : NavigationEnd) => {
			const AUTH_REGEX = /\/auth\/.*/g;

			// Sollte der User ein Servicetoken verwendet haben, welches abelaufen ist,
			// dann soll dies ihm mitgeteilt werden.
			// TODO: Gucken ob so eine art Statemachine sinn in einem Paket machen würde.
			if(!AUTH_REGEX.test(ev.url))
			{
				var action = sessionStorage.getItem("next_action");
				if(action) {
					if(action == "1") {
						this._dialogService.showOkDialog("t_info", "m_link_invalid");
					}
					sessionStorage.removeItem("next_action");
				}
			}

			// Sollte der User eine URL als Lesezeichen gespeichert haben, dann soll er nach der Anmeldung auf der Seite wieder landen.
			if (AUTH_REGEX.test(ev.url)
				&& !ev.url.includes("/order-token") && !ev.url.includes("/auftrags-formular")
				&& !ev.url.includes("/order/token") && !ev.url.includes("/salesoffers/token")
				&& !this._isLoggedIn) {
				sessionStorage.setItem("lastUrl", ev.url);
			}

			// Aktualisiert die gespeicherten Filter.
			// TODO: Da es in der Vergangenheit häufiger Fehler wegen diesen Cookie filtern gab
			// wäre es vielleicht besser es über Queryparameter zu speichern so im CRM.
			if (ev.url.includes("/measurement"))
				this.comparar.Refresh("meas");
			else if (ev.url.includes("/devicetest"))
				this.comparar.Refresh("devicetest");
		})
    }

    ngOnInit() {
		this.onLangChange = this.translateService.translateService.onLangChange.subscribe(langEv => {
			this.useLanguage(langEv.lang);
			this.updateLanguage();

			if(this._cookieService.check("lang")) {
				const lang = this._cookieService.getAll()["lang"];
				if(lang != langEv.lang)
					this._cookieService.set("lang", langEv.lang, undefined, '/');
			}
			else
				this._cookieService.set("lang", langEv.lang, undefined, '/');
		});

		// this.onLangChange = this.translateService.translateService.onLangChange.pipe(
		// 	switchMap(() => {
		// 		if (this._cookieService.check("lang")) {
		// 			const lang = this._cookieService.getAll()["lang"];
		// 			this.useLanguage(lang);
		// 		} else {
		// 			this.translate.use(this.translateService.parseLanguageCode(window.navigator.language));
		// 			this.currentLang = this.translateService.parseLanguageCode(window.navigator.language);
		// 			this.switchLang(this.translateService.parseLanguageCode(window.navigator.language));
		// 		}

		// 		console.log("switch")

		// 		return this.translate.onLangChange;
		// 	})
		// )
        // .subscribe(ev => {
		// 	console.log(ev)
		// 	this.updateLanguage();
        // });
    }

	ngOnDestroy() {
		if (this.onLangChange !== undefined)
			this.onLangChange.unsubscribe();
	}

	updateLanguage(): void {
		const lang = document.createAttribute('lang');
		lang.value = this.translateService.currentLang;
		this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(lang);
	}

    logout() {
        this.authService.doLogout();
    }

    switchLang(lang: string) {
		this.translateService.currentLang = lang;
        this.useLanguage(lang);
        this._cookieService.set("lang", lang, undefined, '/');
    }

	useLanguage(lang : string) {
        this.currentLang = lang;
        this._adapter.setLocale(lang + "-" + lang.toUpperCase());
	}
}
